









































import api from "@/api/index"; //ABP API接口
import {Vue, Component} from "vue-property-decorator";
import {AttachmentDto, AttachmentHostType} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import FundProjectReportEdit from "@/views/fundProject/fundProjectReport/edit.vue";

@Component({
  name: "FundProjectYearSummary",
  components: {
    FundProjectReportEdit,
    PagedTableView,
    AbSelect,
  },
})
export default class FundProjectYearSummaryList extends Vue {
  fundProjectId = "";
  queryForm = {};
  detailId = 0;
  attachments: AttachmentDto[] = [];
  dataId = ""

  created() {
    this.fundProjectId = this.$route.params.fundProjectId;
    this.fetchData();
  }

  // 获取表数据
  async fetchData() {
    return api.attachmentService.getAttachments(
        {
          hostId: this.fundProjectId,
          hostType: AttachmentHostType.ProjectReport
        }
    ).then((res) => {
      this.attachments = res
    });
  }

  // 新建
  handleCreate() {
    this.dataId = this.fundProjectId;
    (this.$refs.projectReport as any).innerVisible = true;
  }

  // 删除
  async handleDelete(id: number) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.attachmentService.delete({id: id})
          .then(() => {
            this.fetchData();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          });
    });
  }
}
