












import {Component, Model, Prop, Vue} from "vue-property-decorator";
import {AttachmentDto, AttachmentHostType} from "@/api/appService";

@Component({
  name: "multipleUploadFile",
})
export default class multipleUploadFile extends Vue {
  @Model("saveSuccess")

  @Prop({required: false})
  hostType!: AttachmentHostType;

  @Prop({required: true})
  hostId!: string | number;

  attachmentList?: AttachmentDto[] = [];


  // 上传成功
  async onSuccess(res: any, file: any) {
    if (res.success) {
      this.$emit("saveSuccess");
    }
  }

  onPreview(file: any) {
    window.open(file.url);
  }

  get uploadParameter() {
    return {hostType: this.hostType, hostId: this.hostId};
  }

  get headers() {
    return {
      "Abp-OrganizationUnitId": (this as any).$store.getters["user/ouId"] || "",
      "Abp-TenantId": (this as any).$store.getters["app/tenantId"],
      Authorization: `Bearer ${(this as any).$store.getters["user/token"]}`,
      ".AspNetCore.Culture": "c=zh-Hans|uic=zh-Hans",
    };
  }
}
