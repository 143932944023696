
















import {Component, Model, Prop, Vue,} from "vue-property-decorator";
import {AttachmentHostType} from "@/api/appService";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import UploadProjectReport from "@/views/fundProject/fundProjectReport/components/UploadProjectReport.vue";

@Component({
  name: "FundProjectReportEdit",
  computed: {
    AttachmentHostType() {
      return AttachmentHostType
    }
  },
  components: {UploadProjectReport, MultipleUploadFile},
})
export default class EditData extends Vue {
  @Model("uploadSuccess")

  @Prop({required: true})
  fundProjectId!: string | number;

  innerVisible = false;


  handleChange() {
    this.innerVisible = false;
    this.$emit("uploadSuccess");
  }

  cancel() {
    this.innerVisible = false;
  }
}
